.resource-center-block-manager-wrapper {
  background: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 0;
  min-width: 0;
  flex-grow: 1;
  z-index: 2;

  .block-manager {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-height: 0;
    padding: 16px 0;

    .block-manager-header {
      display: flex;
      gap: 8px;
      padding: 0 16px;

      .back-btn {
        height: 40px;
        width: 40px;
        justify-content: center;
      }

      .block-manager-title {
        margin: auto 0;
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 4px;
        flex-wrap: wrap;

        .icon-wrapper {
          height: 16px;
          width: 16px;
          display: flex;
          border-radius: 4px;

          i {
            margin: auto;
            font-size: 8px;
            color: #fff;
            padding: 0;
            cursor: default;

            &.isax {
              font-size: 10px;
            }
          }

          &.action,
          &.group {
            background-color: var(--p-200);

            i {
              color: var(--n-800);
            }
          }

          &.resource-center,
          &.add-to-trigger {
            i {
              color: var(--n-700);
              font-size: 16px;
            }
          }
        }
      }

      i {
        cursor: pointer;
        margin-left: auto;
        color: #9598a9;
        padding: 4px;
      }
    }

    .divider {
      margin: auto;
      width: calc(100% - 32px);
    }

    .sections {
      display: flex;
      flex-direction: column;
      gap: 16px;
      overflow-y: auto;

      &.is-loading {
        visibility: hidden;
        height: 0;
      }

      .section {
        padding: 0 16px;

        .section-title {
          margin-bottom: 12px;
          font-weight: 500;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.6);
        }

        .section-items {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          gap: 8px;

          .section-item {
            display: flex;
            flex-direction: column;
            flex: 1 auto;
            cursor: pointer;

            &:hover:not(.selected) {
              .item-name {
                color: #1260eb;
              }
              .image-wrapper {
                border: 2px solid rgba(18, 96, 235, 0.2);

                img {
                  filter: blur(1px);
                  opacity: 0.4;
                }
                .icon-plus-rounded {
                  opacity: 1;
                }
              }
            }

            &.selected {
              .item-name {
                color: #1260eb;
              }
              .image-wrapper {
                border: 2px solid rgba(18, 96, 235, 0.5);

                img {
                  filter: grayscale(0%);
                }
                .icon-checkbox {
                  display: block;
                }
              }
            }

            &.disabled {
              cursor: not-allowed;
            }

            .image-wrapper {
              position: relative;
              margin-bottom: 8px;
              background: #ffffff;
              border: 2px solid rgba(0, 0, 0, 0.1);
              box-shadow: 0px 5.28403px 3.52269px rgba(0, 0, 0, 0.04);
              border-radius: 14px;
              padding: 0;

              img {
                height: 100%;
                width: 100%;
                filter: grayscale(100%);
                transition: all 300ms ease-in-out;
              }

              .icon-checkbox {
                display: none;
                position: absolute;
                top: 8px;
                right: 8px;
                font-size: 24px;
                color: #1260eb;
                z-index: 1;
              }

              .icon-plus-rounded {
                opacity: 0;
                position: absolute;
                font-size: 24px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                transition: opacity 300ms ease-in-out;
                color: #1260eb;
                z-index: 1;
              }
            }

            .item-name {
              margin: auto;
              font-family: 'Montserrat';
              font-weight: 500;
              font-size: 16px;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .block-request-wrapper {
    display: flex;
    flex: 0 0 auto;
    margin-top: auto;
    padding: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}
