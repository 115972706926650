.condition-settings-content {
  width: 360px;

  .dropdown-content {
    overflow: unset;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
    border: 0;
  }

  .condition-settings-wrapper {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .condition-settings-header {
      display: flex;

      .condition-settings-title {
        display: flex;
        gap: 8px;
        align-items: center;
        margin: auto 0;
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 16px;

        .icon-wrapper {
          display: flex;
          height: 24px;
          width: 24px;
          border-radius: 8px;

          &.element {
            background-color: var(--g-100);
          }

          &.input {
            background-color: var(--p-200);
          }

          &.url {
            background-color: var(--b-150);
          }

          &.delay {
            background-color: var(--n-200);
          }

          &.survey-response {
            background-color: var(--b-150);
          }
          &.tour-seen {
            background-color: var(--o-300);
          }

          i {
            margin: auto;
            font-size: 16px;
          }
        }
      }

      i.icon-close {
        cursor: pointer;
        margin-left: auto;
        color: #9598a9;
        font-size: 12px;
        padding: 4px;
      }
    }

    .condition-settings-items {
      display: flex;
      flex-direction: column;
      gap: 12px;

      & > div {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      .section-item {
        display: flex;

        .section-item-title {
          margin: auto 0;
        }

        .radio-options-wrapper {
          display: flex;
          flex-direction: column;
          gap: 4px;
          border-radius: 8px;
          border: 1px solid var(--n-100);
          padding: 4px 0;

          .option {
            display: flex;
            gap: 8px;
            padding: 8px;
            align-items: center;

            input {
              cursor: pointer;
            }
          }
        }

        .select-group-wrapper {
          width: 100%;

          .jimo-select__control {
            border-radius: 12px;
            height: 40px;

            .jimo-select__value-container {
              padding: 0 12px;
            }
          }
        }

        .input-group {
          height: 40px;
          width: 100%;
          display: inline-flex;

          .input {
            flex: 1 auto;
            border-radius: 12px;
          }

          &.labeled-left {
            .input {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }

          &.labeled-right {
            .input {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              border-right: 0;
            }

            .label-right {
              border-top-right-radius: 12px;
              border-bottom-right-radius: 12px;
              padding: 10px;
            }
          }
        }
      }

      .section-item-content {
        margin-left: auto;
        width: 200px;
      }
    }
  }
}
