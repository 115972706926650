@import './../../Colors';

.s-poke {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  background: #f7f9fe;

  &.is-loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.is-empty {
    display: flex;
    align-items: center;
    justify-content: center;

    .main {
      flex: 0 0 235px;
      display: flex;
      flex-flow: column;
      align-items: center;
      text-align: center;
      font-size: 24px;
      font-weight: 500;
    }
  }

  .poke-content-wrapper {
    overflow-y: auto;
    display: flex;
    flex: 1 auto;
    height: calc(100% - 144px);
    width: 100%;

    .loader-wrapper {
      margin: auto;
    }

    .poke-content {
      display: flex;
      flex-flow: column;
      gap: 56px;
      padding: 72px;
      padding-top: 36px;
      flex: 1 auto;
      max-width: 1042px;
      margin: auto;
      margin-top: 0;
      overflow: visible;

      .section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;

        .section-title {
          display: flex;
          align-self: stretch;
          align-items: center;
          color: var(--neutral-800-black, var(--neutral-neutral-800, #071331));
          font-family: Montserrat;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 125%; /* 30px */
          justify-content: space-between;

          .dropdown-trigger {
            margin-left: auto;
          }

          .section-title-actions {
            display: flex;
            gap: 16px;
          }
        }

        .tabs-wrapper {
          display: flex;
          gap: 20px;
          align-self: stretch;
        }

        .section-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 32px;
          align-self: stretch;

          & > div {
            display: flex;
            gap: 20px;
            align-self: stretch;
          }
        }

        .tabs-wrapper {
          .tabs {
            width: 100%;

            .tab-list-item {
              .issues-count {
                display: flex;
                height: 25px;
                padding: 6px 8px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 10px;
                box-sizing: border-box;
                align-self: center;
                border-radius: 27px;
                background: var(--surface-accent-primary-negative, #ff4646);

                .count {
                  color: var(--neutral-white, var(--neutral-neutral-0, #fff));
                  font-family: Inter;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 150%; /* 24px */
                }
              }
            }
          }
        }
      }
    }
  }
}

.action-export-data-content {
  margin-left: 4px;
  margin-top: 4px;
}
.menu-export-data {
  .menu-item {
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 500;

    span {
      display: block;
      position: relative;
      top: 1px;
      font-weight: 600;
      margin-left: 4px;
      background-color: #e4e4e4;
      padding: 4px 8px;
      border-radius: 6px;
      font-size: 12px;
    }
  }
}
