.s-settings-integrations-webhook {
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;

  .settings-title {
    .divider {
      margin: 32px 0 0 0;
    }
  }

  .card-webhooks {
    .webhooks-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title-wrapper {
        a {
          color: var(--b-400);
        }
      }
    }
    .webhooks-list {
      padding-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .webhook-item {
        display: flex;
        align-items: center;
        padding: 16px;
        border: 1px solid var(--n-300);
        border-radius: 16px;
        cursor: pointer;
        &:hover {
          background-color: var(--n-50);
        }

        .icon-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 12px;
          margin-right: 16px;
          border: 1px solid var(--n-300);
        }

        .webhook-name-wrapper {
          display: flex;
          flex-direction: column;
          gap: 10px;
          flex: 1;

          .webhook-details {
            display: flex;
            gap: 8px;

            .webhook-status {
              background-color: var(--o-100);
              color: var(--o-500);
              padding: 4px 12px;
              border-radius: 12px;
              text-transform: capitalize;
              &.is-live {
                background-color: var(--g-100);
                color: var(--g-500);
              }
            }
            .webhook-events {
              padding: 4px 12px;
              border-radius: 12px;
              border: 1px solid var(--n-300);
              display: flex;
              align-items: center;
              gap: 4px;

              i {
                font-size: 18px;
              }
            }
            .webhook-url {
              padding: 4px 12px;
              border-radius: 12px;
              border: 1px solid var(--n-300);
              display: flex;
              align-items: center;
              gap: 4px;

              i {
                font-size: 18px;
              }
              span {
                max-width: 320px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }
          }
        }

        .btn-menu-webhook {
          padding: 12px 16px;
          border-radius: 8px;

          i {
            font-size: 12px;
          }
        }
      }
    }
  }
}
