.block-resource-center-container {
  display: flex;

  .block-resource-center {
    width: 360px;
    transition: width 200ms ease, background-color 500ms ease;
    display: flex;
    flex-direction: column;
    animation: fadeInUpSpring 600ms ease forwards;
    overflow: hidden;

    &.is-mini {
      animation: fadeOutDown 200ms ease forwards;
      pointer-events: none;
    }

    &.disable-animations {
      animation: none !important;

      * {
        animation: none !important;
      }
    }

    &.is-success {
      overflow: hidden;
    }
  }

  .poke-block-clickable {
    cursor: pointer;
    position: relative;
  }
}

@keyframes fadeInUpSpring {
  0% {
    opacity: 0;
    transform: translateY(40%);
  }
  40% {
    opacity: 1;
    transform: translateY(-1%);
  }
  70% {
    transform: translateY(1%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(20%);
  }
}
