.resource-center-navigator-wrapper {
  flex-grow: 1;
  padding: 12px;
  background: #fff;
  min-width: 0;

  .resource-center-navigator {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .section-title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
    }

    .blocks-wrapper {
      .blocks-title {
        cursor: pointer;
        display: flex;
        background: white;
        padding: 0;
        border-radius: 12px;
        transition: border-radius 0.3s ease-in-out;

        &.is-open {
          border-radius: 12px 12px 0px 0px;
        }

        i {
          font-size: 16px;
          padding: 12px;
          margin: auto 0;
          color: var(--n-700);
        }

        .icon-expand-wrapper {
          margin-right: 8px;
          padding: 8px;
          font-size: 12px;
          display: flex;

          i {
            padding: 0;
            margin: auto;
            font-size: 12px;
            display: block;
            transition: 0.3s;
          }
        }

        &.activated {
          background: #f4f8ff;
        }

        &:hover {
          background: var(--b-150);

          .step-name,
          i {
            color: var(--n-800);
          }
        }

        &.selected {
          background: #1260eb;

          .step-name,
          i {
            color: #fff;
          }
        }

        .step-name-input {
          padding: 0;
          line-height: 16px;
          margin-right: auto;

          .input-main-wrapper {
            border: 0;
            border-radius: 0;

            .input {
              color: #fff;
              padding: 0;
              font-size: 14px;
            }
          }
        }
        .step-name {
          padding: 12px 0;
          height: 16px;
          flex: 1 auto;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: auto;
          color: var(--n-700);

          &.is-empty {
            color: #b3b3b3;
          }
        }
      }

      .blocks {
        border-radius: 0 0 12px 12px;

        &.expanded {
          overflow: visible;
        }

        .navigator-item {
          cursor: pointer;
          background: white;
          display: flex;
          padding: 12px;
          position: relative;
          &.activated {
            background: #f4f8ff;

            &.top-block {
              border-radius: 12px 12px 0px 0px;
            }
          }

          &.level-1 {
            padding-left: 40px;

            .drag-handle {
              left: 18px;
            }
          }

          &.level-2 {
            padding-left: 60px;

            .drag-handle {
              left: 38px;
            }
          }

          &.level-3 {
            padding-left: 80px;

            .drag-handle {
              left: 58px;
            }
          }

          .icon-wrapper {
            height: 16px;
            width: 16px;
            margin-right: 12px;
            display: flex;
            background: #e6fde8;
            border-radius: 4px;
            flex-shrink: 0;

            &.container {
              background: var(--p-200);
            }

            &.progress-checklist {
              background: var(--o-200);
            }

            i {
              margin: auto;
              font-size: 10px;
              color: #000;

              &.isax {
                font-size: 12px;
              }
            }
          }

          &:hover {
            background: var(--b-150);

            .delete-icon-wrapper {
              display: flex;
            }

            .name {
              color: var(--n-800);
            }
          }

          &.selected {
            background: #1260eb;

            .name {
              color: #fff;
            }
          }

          .name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--n-700);
          }

          .delete-icon-wrapper {
            display: none;
            margin-left: auto;

            i {
              margin: auto;
              font-size: 12px;
            }
          }

          .drag-handle {
            position: absolute;
            cursor: grab;
            left: 12px;
            top: 0;
            bottom: 0;
            margin: auto;
            display: flex;
            align-items: center;
            gap: 1px;
            color: var(--n-600);
            font-size: 10px;
          }
        }

        .add-block-wrapper {
          display: flex;
          padding: 12px;
          align-items: center;
          gap: 12px;
          border: 1px solid #e5e5e5;
          border-radius: 0 0 12px 12px;
          background: white;
          justify-content: center;
          cursor: pointer;
          overflow: visible;

          &.detached {
            border-radius: 8px;
            margin-top: 16px;
            margin-bottom: 3px;
          }

          .add-block-content {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            i {
              font-size: 12px;
            }
          }
        }

        .draggable-wrapper {
          &.selected {
            margin-bottom: 4px;
          }
        }
      }
    }

    &.is-dragging {
      .blocks-title {
        opacity: 0.3;
      }

      .blocks {
        .navigator-item {
          opacity: 0.3;
        }
      }

      [data-rbd-draggable-id]:not(.same-droppable-group) {
        & > .navigator-item {
          opacity: 0.3;
          transition: opacity 0.2s ease;
        }
      }

      .same-droppable-group {
        & > .navigator-block-item {
          opacity: 1;
        }
      }
    }
  }
}
