.resource-center-layout {
  display: flex;
  flex-direction: column;
  overflow: visible;
  align-self: stretch;
  min-height: 0;

  .resource-center-header {
    display: flex;
    gap: 16px;
    width: 100%;
    box-sizing: border-box;
    z-index: 1000;

    .resource-center-header-content {
      flex: 1;
      transition: transform 0.3s ease-out;
      max-width: 70%;
    }

    .resource-center-header-actions {
      flex: 0;
      margin-left: auto;
    }
  }

  .resource-center-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    overflow-x: hidden;
    overflow-y: auto;
    // hack to make clickable overlay appear for first item
    padding-top: 104px;
    margin-top: -4px;
  }
}
